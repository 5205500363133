import { useEffect, useState } from "react";

import Navbar from "../../components/Dashboard/Navbar/Navbar";
import InfoHeader from "../../components/Dashboard/InfoHeader";
import { getSvg } from "../MonthlyChecklist/checkListAssets/checklistAsset";
import WorkflowStep from "../../components/Workflow/WorkflowStep";
import {ReactFlow} from '@xyflow/react';
import FlowChart from "./FlowChart";
import FlowWithoutLibrary from "./WithoutLibrary/FlowWithoutLibrary";
import { Box, Button, Typography } from "@mui/material";
import { useParams } from "react-router";
import axios from "../../utils/axios";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import toast from "react-hot-toast";
import html2pdf from 'html2pdf.js';
import { getShortFirmname, getUserId, getUserSID } from "../../utils/helpers";

const WorkflowLayout = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [stepsForWorkflows, setStepsForWorkflows] = useState([])
  const { workflowId, month_year } = useParams();
  const [refresh, setRefresh] = useState(false);

  const handleRefresh = ()=>{
   setRefresh(prev=> !prev);
  }

  useEffect(()=>{
    const fetchAllStepsForWorkflow = async()=> {
      const allStepsForWorkflow = await axios.get(`/getStepsForWorkflow?workflowId=${workflowId}`);
      if(allStepsForWorkflow.data.stepsForWorkflow){
        setStepsForWorkflows([...allStepsForWorkflow.data.stepsForWorkflow]);
      }
    }
    fetchAllStepsForWorkflow();
  },[refresh]);

  const isButtonDisabled = () =>{
    let isDisabled = true;
    stepsForWorkflows.forEach(step=>{
      isDisabled = isDisabled && step.step_status.toLowerCase() == "done";
    })
    return !isDisabled;
  }

  const handleMarkAsDone = async() => {
    try{
      const resp = await axios.patch('/updateWorkflowStatus',{workflowId});
      if(resp){
        const element = document.getElementById('capture');

        // Set options for html2pdf (adjust margins, filename, image quality, etc. as needed)
        const opt = {
          margin: 0.5,
          filename: 'snapshot.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        // Convert the HTML element to a PDF blob
        html2pdf()
          .set(opt)
          .from(element)
          .outputPdf('blob')
          .then(async(pdfBlob) => {
            // Create FormData and append the PDF blob
            const formData = new FormData();
            formData.append('files', pdfBlob, 'snapshot.pdf');
                // let path = `${id}/${modalData.component.replace(/\s+/g, '_')}`;
            let path = `${workflowId}`;
            const short_firmname = getShortFirmname();
            const sid = getUserSID();
            const currentUserId = getUserId();
            formData.append("short_firmname", short_firmname);
            formData.append("sid", sid);
            formData.append("path", path);
            formData.append("workflow_id", workflowId);
            formData.append("file_title", "completion_document")

            console.log({formData, pdfBlob, path});
            try {
              const response = await axios.post("/upload_workflow_completion_document", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
              toast.success("Bank reconciliation flow completed!");
            } catch (error) {
              console.error("Upload failed:", error);
              toast.error("Could not save flow. Something went wrong!");
            }
          })
          .catch(error => {
            console.error('Error generating PDF:', error);
          });
      }
    }catch(error){
      toast.error('Cannot complete workflow')
    }
  }
  return (
    <section className="wrapper">
    <aside className="mainSide">
      <Navbar setIsNavOpen={setIsNavOpen} />
    </aside>
    <main>
      <InfoHeader
        title={`Welcome `}
      />
      <h5 className="calcTitle mb-0">Bank reconciliation workflow</h5>

      <div className="row" id="capture">
        <Box sx={{border: "2px solid #307FF4", borderRadius: "24px", height: "878px", width: "1679px",  overflow: "hidden",padding: 0}}>
          <Box sx={{backgroundColor: "#FBFCFE", borderBottom: "1px solid #73C3FD",display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width:"100%", paddingTop: 1, paddingLeft: 2, paddingBottom: 1}}>
           <Typography> Bank reconciliation workflow - {month_year}</Typography>
           <Button disabled={isButtonDisabled()} onClick={handleMarkAsDone} variant="outlined" sx={{border: "1px solid #73C3FD", color: "#000000", height:"28px",mr:2}} startIcon={<CheckOutlinedIcon sx={{ color: "#73C3FD" }} />}>Mark as done</Button>
          </Box>
          <FlowChart data={stepsForWorkflows} handleRefresh={handleRefresh}/>
        </Box>

      </div>

      <div className="pb-3"> </div>
    </main>
  </section>
  )
};



export default WorkflowLayout;
